/**
 * Calls a callback function with the percent progress of the request.
 *
 * @param {SyntheticEvent} event The onprogress synthetic event
 * @param {func} callback Function to be called with the percent progress of the request.
 */
const _updateProgress = ({event, callback}) => {
  if (event.lengthComputable) {
    const percentComplete = (event.loaded / event.total) * 100;
    callback(percentComplete);
    return;
  }
  callback(-1);
};

/**
 * Upload a file to S3.
 *
 * @param {Object} options - The options for uploading the file.
 * @param {string} options.url - The root URL of the S3 bucket to send the POST request to.
 * @param {Object} options.fields - An object of key-value pairs that specify the fields to supply with the request.
 * @param {File} options.file - A file object that will be uploaded to S3.
 * @param {(progress: string) => void} options.onProgress - A callback function that is called with the progress of the upload request as a percent.
 */
const uploadFile = async ({url, fields, file, onProgress}) => {
  const formData = new FormData();
  for (const key of Object.keys(fields)) {
    formData.append(key, fields[key]);
  }
  formData.append('file', file);

  // Submit the POST request
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.upload.onprogress = (event) => _updateProgress({event, callback: onProgress});
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 204) {
          resolve(xhr.responseText);
        } else {
          reject(xhr.responseText);
        }
      }
    };
    xhr.send(formData);
  });
};

const S3 = {
  uploadFile,
};

export default S3;
