// Libraries
import React from 'react';
import ReactDOM from 'react-dom';

import {useCallback, useRef, useState} from '@supermove/hooks';

const useHover = () => {
  const [isHovered, setIsHovered] = useState(false);

  // Wrap in useCallback so that these aren't redefined each render.
  const handleMouseOver = useCallback(() => setIsHovered(true), []);
  const handleMouseOut = useCallback(() => setIsHovered(false), []);

  // Store the latest node passed to the refCallback.
  const ref = useRef<Element | null | Text>();

  const refCallback = useCallback(
    (item: React.ReactInstance) => {
      // Whenever there is a new node, we need to remove the event listeners
      // from the previous reference and add to the new reference.
      if (ref.current) {
        ref.current.removeEventListener('mouseover', handleMouseOver);
        ref.current.removeEventListener('mouseout', handleMouseOut);
      }

      // We have to look up the actual DOM node.
      const node = ReactDOM.findDOMNode(item);
      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener('mouseover', handleMouseOver);
        ref.current.addEventListener('mouseout', handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut],
  );

  return {
    isHovered,
    ref: refCallback,
    handleToggleOff: () => setIsHovered(false),
  };
};

export default useHover;
