// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

const PreventPropagationContainer = Styled.View`
  flex-shrink: 1;
`;

const PREVENT_PROPAGATION_KEYS = ['Enter', 'Space', ' '];

const PreventPropagation = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (PREVENT_PROPAGATION_KEYS.includes(e.key)) {
      e.stopPropagation();
    }
  };

  return (
    <PreventPropagationContainer
      onStartShouldSetResponder={() => true}
      onTouchEnd={(e) => e.stopPropagation()}
      style={style}
    >
      <div onKeyDown={handleKeyDown} role={'presentation'}>
        {children}
      </div>
    </PreventPropagationContainer>
  );
};

export default PreventPropagation;
