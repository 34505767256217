// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import LoadingButton from './LoadingButton';
import {ButtonProps} from './Button';

export type StatefulButtonProps = ButtonProps & {
  error?: boolean;
  errorColor?: string;
  loading?: boolean;
  onError?: () => void;
  onSuccess?: () => void;
  success?: boolean;
  successColor?: string;
};

const StatefulButton = ({
  color,
  disabled = false,
  disabledColor,
  error = false,
  errorColor = colors.Pink600,
  loading = false,
  onError = () => {},
  onSuccess = () => {},
  success = false,
  successColor = colors.green.status,
  children,
  ...props
}: StatefulButtonProps) => (
  <Lifecycle
    error={error}
    onUpdate={(previousProps: {success: boolean; error: boolean}) => {
      if (success && previousProps.success !== success) {
        setTimeout(() => onSuccess(), 1000);
      } else if (error && previousProps.error !== error) {
        setTimeout(() => onError(), 1000);
      }
    }}
    success={success}
  >
    <LoadingButton
      disabled={error || disabled || loading || success}
      loading={loading}
      {...props}
      disabledColor={error ? errorColor : success ? successColor : disabledColor}
      style={success || error ? {transitionProperty: 'all', transitionDuration: '0.5s'} : {}}
    >
      {children}
    </LoadingButton>
  </Lifecycle>
);

export default StatefulButton;
