// Libraries
import Brainfish from '@brainfish-ai/web-widget';

// Supermove
import {useMountEffect} from '@supermove/hooks';

const useBrainfishWidget = ({apiKey, isVisible, onOpenChat}) => {
  useMountEffect(() => {
    // Initialize the Brainfish module.
    Brainfish.Widgets.init({
      widgetKey: apiKey,
      overrides: {
        nextBestActions: [
          {
            type: 'callback',
            label: 'Chat with Support',
            value: ({query, answer}) => {
              // Close the widget before opening the chat widget.
              Brainfish.HelpWidget.close();

              // Open chat widget and prepopulate with question asked.
              if (onOpenChat) {
                onOpenChat({query});
              }
            },
          },
        ],
      },
    });
  });

  return {
    handleOpen: () => {
      Brainfish.HelpWidget.open();
    },
  };
};

export default useBrainfishWidget;
