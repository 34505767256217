// Libraries
import React from 'react';
import userflow from 'userflow.js';

// Supermove
import {Environment} from '@supermove/sdk';

// App
import AppName from '@shared/modules/App/enums/AppName';

class Userflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isIdentified: false};
  }

  get isValidApp() {
    const appName = Environment.getAppName();
    const validAppNames = [AppName.MANAGER, AppName.SALES];
    return validAppNames.includes(appName);
  }

  get isEnabled() {
    return this.props.apiKey && this.isValidApp;
  }

  componentDidMount() {
    const userflowKey = this.props.apiKey;
    this.setState({
      isIdentified: false,
    });
    // Keeps app from erroring if no key found
    if (!userflowKey) {
      return;
    }
    userflow.init(userflowKey);
    // Disable the default Userflow events
    // We have our own events we track so do not need these which leads to duplicates
    userflow.setPageTrackingDisabled(true);
  }

  handleIdentify = ({
    id,
    email,
    fullName,
    Organization,
    Role,
    position,
    userflowSecuritySignature,
    createdAt,
  }) => {
    if (this.isEnabled) {
      userflow.identify(
        id,
        {
          name: fullName,
          email,
          orgSlug: Organization,
          role: Role,
          position,
          signed_up_at: createdAt,
        },
        {
          signature: userflowSecuritySignature,
        },
      );
      this.setState({
        isIdentified: true,
      });
    }
  };

  handleTrack = ({event, data}) => {
    // For Userflow, user must be identified before calling other functions
    if (this.isEnabled && this.state.isIdentified) {
      userflow.track(event, data);
    }
  };

  handleReset = () => {
    // For Userflow, user must be identified before calling other functions
    if (this.isEnabled && this.state.isIdentified) {
      userflow.reset();
      this.setState({
        isIdentified: false,
      });
    }
  };

  render() {
    return this.props.children({
      identify: this.handleIdentify,
      track: this.handleTrack,
      reset: this.handleReset,
    });
  }
}

export default Userflow;
