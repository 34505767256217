import color from 'color';

export interface ColorObject {
  [key: string]: {
    text: string;
    background: string;
  };
}

export const BADGE: ColorObject = {
  Blue: {
    text: '#102C60',
    background: '#e5f3ff',
  },
  Red: {
    text: '#c53a33',
    background: '#fdebee',
  },
  Orange: {
    text: '#bc4d1a',
    background: '#fdf2e0',
  },
  Yellow: {
    text: '#c19d0c',
    background: '#fdf7df',
  },
  Green: {
    text: '#008633',
    background: '#eefff5',
  },
  Gray: {
    text: '#616161',
    background: '#fafafa',
  },
};

export const black = '#000000';
export const gray900 = '#212121'; // Primary
export const gray700 = '#616161';
export const gray500 = '#9e9e9e';
export const gray300 = '#e0e0e0';
export const gray50 = '#fafafa';
export const white = '#ffffff';

export const blue900 = '#102C60';
export const blue400 = '#276EF1'; // Primary
export const blue200 = '#5baaff';
export const blue50 = '#e5f3ff';

export const red700 = '#c53a33'; // Primary
export const red400 = '#e74d3c';
export const red50 = '#fdebee';

export const orange700 = '#bc4d1a'; // Primary
export const orange400 = '#f2a02c';
export const orange50 = '#fdf2e0';

export const green700 = '#008633'; // Primary
export const green400 = '#05c46b';
export const green50 = '#eefff5';

export const yellow700 = '#c19d0c'; // Primary
export const yellow400 = '#f1c40f';
export const yellow50 = '#fdf7df';

export const purple700 = '#6d3898'; // Primary
export const purple400 = '#9b59b6';
export const purple50 = '#f0e7f4';

export const displayRed = '#E74C3C';
export const displayYellow = '#F1C40F';
export const displayGreen = '#27AE60';
export const displayGrey = gray700;
export const displayBlue = '#34ACE0';

const COLOR_TO_BACKGROUND_COLOR = {
  // Primary colors
  [blue400]: blue50,
  [red700]: red50,
  [orange700]: orange50,
  [green700]: green50,
  [yellow700]: yellow50,
  [purple700]: purple50,
  [gray700]: gray300,

  // Custom colors
  // see gray700 for displayGrey
  [displayRed]: red50,
  [displayYellow]: yellow50,
  [displayGreen]: green50,
  [displayBlue]: blue50,
} as const;

const COLOR_TO_TEXT_COLOR = {
  [displayRed]: red700,
  [displayYellow]: yellow700,
  [displayGreen]: green700,
  [displayGrey]: gray700,
  [displayBlue]: blue900,
} as const;

const DISPLAY_COLOR_OPTIONS = {
  Red: displayRed,
  Yellow: displayYellow,
  Green: displayGreen,
  Grey: displayGrey,
  Blue: displayBlue,
} as const;

export const getBackgroundColor = (hex: keyof typeof COLOR_TO_BACKGROUND_COLOR): string => {
  return COLOR_TO_BACKGROUND_COLOR[hex] || color(hex).alpha(0.15).string();
};

export const getTextColor = (color: keyof typeof COLOR_TO_TEXT_COLOR): string => {
  return COLOR_TO_TEXT_COLOR[color] || color;
};

export const getDisplayColorOptions = () => {
  return Object.entries(DISPLAY_COLOR_OPTIONS).map(([key, value]) => ({
    label: key as keyof typeof DISPLAY_COLOR_OPTIONS,
    value,
  }));
};

const colors = {
  BADGE,
  transparent: 'transparent',
  alpha: (hex: string, amount: number): string => color(hex).alpha(amount).string(),
  getBackgroundColor,
  getTextColor,
  getDisplayColorOptions,
  black,
  gray900,
  gray700,
  gray500,
  gray300,
  gray50,
  gray: {
    primary: gray900,
    secondary: gray700,
    tertiary: gray500,
    disabled: gray300,
    border: gray300,
    background: gray50,
    avatarBackground: '#d9d9d9',
  },
  white,
  blue900,
  blue400,
  blue200,
  blue50,
  blue: {
    interactive: blue400,
    hover: blue200,
    accent: blue50,
    accentDark: blue900,
    branding: '#276ef1',
  },
  red700,
  red400,
  red50,
  red: {
    warning: red700,
    hover: red400,
    accent: red50,
    branding: '#ff505f',
  },
  orange700,
  orange400,
  orange50,
  orange: {
    status: orange700,
    hover: orange400,
    accent: orange50,
  },
  green700,
  green400,
  green50,
  green: {
    status: green700,
    hover: green400,
    accent: green50,
    branding: '#3ccd78',
  },
  yellow700,
  yellow400,
  yellow50,
  yellow: {
    status: yellow700,
    hover: yellow400,
    accent: yellow50,
    branding: '#ffc846',
  },
  purple700,
  purple400,
  purple50,
  purple: {
    status: purple700,
    hover: purple400,
    accent: purple50,
  },
  hover: blue50,
  Pink600: '#ed254e',
  Blue201: '#5caaff',
  Blue10: '#eff6fd',
} as const;

export type Colors = typeof colors;

export default colors;
