// Libraries
import React from 'react';

import {useRef} from '@supermove/hooks';
import {ExecutionEnvironment} from '@supermove/sdk';

// Assets
import 'react-quill/dist/quill.snow.css';
import './RichTextInput.css';

const smallerFontOption = '6px';
const DEFAULT_LABEL = 'Default';

const FONT_SIZES = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
];

const getModules = ({
  hasCustomToolbar,
  fontSizes,
}: {
  hasCustomToolbar?: boolean;
  fontSizes: (string | boolean)[];
}) => {
  if (hasCustomToolbar) {
    // In order for 14px to be displayed as the default size, it needs to be replaced with "false" in the toolbar
    // Following this guide https://github.com/slab/quill/issues/3135#issuecomment-674508453
    const defaultFontIndex = fontSizes.indexOf(DEFAULT_LABEL);
    const fontSizesWithDefault = [...fontSizes];
    fontSizesWithDefault.splice(defaultFontIndex, 1, false);

    return {
      toolbar: {
        container: [
          [{header: [1, 2, false]}],
          [{size: fontSizesWithDefault}],
          ['bold', 'italic', 'underline', 'strike'],
          ['link'],
          [{align: []}, {list: 'ordered'}, {list: 'bullet'}],
          [{color: []}, {background: []}],
          ['clean'],
        ],
      },
      // React Quill has a known issue where extra <p><br></p> tags are added to white space on save.
      // Disabling matchVisual solves the issue, blocking the extra tags from being added.
      clipboard: {
        matchVisual: false,
      },
    };
  }

  return {};
};

interface RichTextInputProps {
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  value: string;
  setFieldValue: (name: string | undefined, value: string) => void;
  style: React.CSSProperties;
  hasCustomToolbar?: boolean;
  hasSmallerFontOption?: boolean;
  shouldUseDivsForNewLines?: boolean;
}

const RichTextInput = ({
  disabled,
  name,
  placeholder,
  value,
  setFieldValue,
  style,
  hasCustomToolbar,
  hasSmallerFontOption,
  shouldUseDivsForNewLines,
}: RichTextInputProps) => {
  const fontSizes = useRef(
    hasSmallerFontOption
      ? [DEFAULT_LABEL, smallerFontOption, ...FONT_SIZES]
      : [DEFAULT_LABEL, ...FONT_SIZES],
  );
  if (!ExecutionEnvironment.canUseDOM) {
    return null;
  }

  const ReactQuill = require('react-quill');
  const {Quill} = ReactQuill;

  const Size = Quill.import('attributors/style/size');
  Size.whitelist = fontSizes.current;
  Quill.register(Size, true);

  // By default, Quill uses P tags for block elements. This changes it to use DIV tags.
  // This fixes discrepencies between how email servers and the editor render block elements.
  if (shouldUseDivsForNewLines) {
    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
  }

  // By default, Quill does not santize links to include a protocol.
  // This custom sanitizer adds a protocol if the user does not include one.
  // ie. "www.google.com" -> "//www.google.com"
  const Link = Quill.import('formats/link');
  const builtInFunc = Link.sanitize;
  Link.sanitize = function customSanitizeLinkInput(linkValueInput: string) {
    let val = linkValueInput;

    // NOTE(jholston): As part of the rollout, we'll only sanitize links that have a custom toolbar
    // STYLING NOTE(shopking): If making a change here that adds functionality to the toolbar, the .ql-container class in RichTextInput.css
    //                         may need to be updated to accommodate subtracting more pixels from the height of the input container
    if (hasCustomToolbar) {
      // do not sanitize if there's a custom protocol (eg. mailto: or tel:) or if it contains http or https
      const startsWithWordsRegex = /^\w+:/;
      const startsWithHttpsRegex = /^https?:/;
      const startsWithSlashesRegex = /^\/\//;

      if (
        !startsWithWordsRegex.test(val) &&
        !startsWithHttpsRegex.test(val) &&
        !startsWithSlashesRegex.test(val)
      ) {
        val = `//${val}`;
      }
    }

    return builtInFunc.call(this, val); // retain the built-in sanitize logic
  };

  return (
    <ReactQuill
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={(value: string) => setFieldValue(name, value)}
      style={style}
      // TODO(jholston): Update all instances of RichTextInput to use the custom toolbar
      modules={getModules({hasCustomToolbar, fontSizes: fontSizes.current})}
    />
  );
};

export default RichTextInput;
